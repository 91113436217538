<template>
  <div class="modal"
    v-if="value"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'
      "
    tabindex="-1"
    role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable"
      role="document">
      <div class="modal-content">
        <div class="modal-header d-flex align-items-center ">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div>
              <h3 class="mb-0 d-flex align-items-center">
                <div class="icon-title d-flex align-items-center justify-content-center">
                  <icon width="30"
                    height="30"
                    name="wine-bottle"></icon>
                </div>
                <div>
                  <div>
                    {{
                      row_wi.wi_id > 0
                        ? "Modification fiche vin"
                        : "Création d'une fiche vin"
                    }}
                  </div>
                  <div class="fs-6 fw-light">
                    Les champs avec une astérisque sont obligatoires
                  </div>
                </div>
              </h3>
            </div>
            <div class="d-flex align-items-center"
              v-if="!row_wi.wi_revendication || !row_wi.wi_analyse">
              <img src="/images/icons/icon-stop.png"
                alt="icon stop" />
              <div>
                <div class="text-danger fs-5 fst-italic ms-2">
                  Fichiers manquants
                </div>
                <ul class="mb-0">
                  <li v-if="!row_wi.wi_revendication">
                    Déclaration de revendication
                  </li>
                  <li v-if="!row_wi.wi_analyse">Rapport d'analyse</li>
                </ul>
              </div>
            </div>
            <div>

            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="frame bg-gray">
            <div class="row">
              <div class="col-md-3 pt-4">
                <div class="d-flex justify-align-end align-items-end flex-column">
                  <div>
                    <div class="d-flex align-items-center"
                      v-if="$Utils.isAdmin()">
                      <div v-if="row_wi.wi_valid"
                        class="me-2 fw-bold">
                        Vin validé
                      </div>
                      <div v-else
                        class="me-2 fw-bold">Vin non validé</div>
                      <label class="switch">
                        <input v-model="row_wi.wi_valid"
                          type="checkbox"
                          :name="$Utils.randomstring('wi_valid')"
                          @change="inputValidWine" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div class="mt-1">
                    <div class="d-flex align-items-center"
                      v-if="$Utils.isAdmin()">
                      <div v-if="row_wi.wi_valid2"
                        class="me-2 fw-bold">
                        Non effacé
                      </div>
                      <div v-else
                        class="me-2 fw-bold">Vin effacé</div>
                      <label class="switch">
                        <input v-model="row_wi.wi_valid2"
                          type="checkbox"
                          :name="$Utils.randomstring('wi_valid2')"
                          @change="inputValidWine2" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-9">
                <m-form-text label="Explications"
                  class="mb-2"
                  :rows="3"
                  type="textarea"
                  v-model="row_wi.wi_explain"
                  :name="$Utils.randomstring('wi_explain')"
                  @input="inputMadailleWine"></m-form-text>

              </div>
            </div>
          </div>
          <div class="frame bg-gray">
            <div class="row">
              <div class="col-md-6">
                <choice-candidat :row_pa="row_pa2"
                  @emitChangeCandidat="emitChangeCandidat"
                  @emitEditCandidatJure="emitEditCandidatJure"></choice-candidat>
              </div>
              <div class="col-md-6">
                <h4>Résultats</h4>
                <m-form-text label="N° d'échantillon"
                  class="mb-2"
                  :rows="1"
                  type="textarea"
                  v-model="row_wi.wi_numechantillon"
                  :name="$Utils.randomstring('wi_numechantillon')"
                  @input="inputMadailleWine"></m-form-text>
                <m-form-select label="Médaille"
                  class="mb-2"
                  :name="$Utils.randomstring('wi_medaille')"
                  :items="$store.state.items_medailles"
                  v-model="row_wi.wi_medaille"
                  :rules="[$Validation.mandatory]"
                  :disabled="disabledMedaille"
                  @input="inputMadailleWine"></m-form-select>
                <m-form-checkbox label="Médaille d'excellence"
                  class="mb-2"
                  :name="$Utils.randomstring('wi_prixexcellence')"
                  v-model="row_wi.wi_prixexcellence"
                  :disabled="disabledMedaille"
                  @input="inputMadailleWine"></m-form-checkbox>
                <m-form-text label="Médaille commentaire"
                  class="mb-2"
                  :rows="1"
                  type="textarea"
                  v-model="row_wi.wi_medaille_commentaire"
                  :name="$Utils.randomstring('wi_medaille_commentaire')"
                  :disabled="disabledMedaille"
                  @input="inputMadailleWine"></m-form-text>
                <m-form-text label="Médaille autre commentaire"
                  class="mb-2"
                  :rows="1"
                  type="textarea"
                  v-model="row_wi.wi_medaille_commentaire_other"
                  :name="$Utils.randomstring('wi_medaille_commentaire_other')"
                  :disabled="disabledMedaille"
                  @input="inputMadailleWine"></m-form-text>
                <m-form-text label="Médaille auteur"
                  v-model="row_wi.wi_medaille_auteur"
                  :name="$Utils.randomstring('wi_medaille_auteur')"
                  :disabled="disabledMedaille"
                  @input="inputMadailleWine"></m-form-text>
              </div>
            </div>
          </div>
          <form-wine ref="formWine"
            from="backoffice"
            :wi_id="wi_id"
            @formWineAction="formWineAction"></form-wine>
        </div>
        <div class="modal-footer d-flex flex-row">
          <!-- <button
            type="button"
            class="btn btn-outline-danger"
            :disabled="row_wi.wi_id ? false : true"
            @click="deleteConfirmWin"
          >
            Supprimer
          </button> -->
          <div class="ms-auto"></div>

          <!--           <div class="form-check">
            <label class="form-check-label" for="active">Actif</label>
            <input
              class="form-check-input"
              type="checkbox"
              :name="$Utils.randomstring('wi_active')"
              v-model="row_wi.wi_active"
            />
          </div> -->

          <button type="button"
            class="btn btn-secondary "
            @click="cancelWin">
            Annuler
          </button>
          <button type="button"
            class="btn btn-primary ms-2"
            @click="tryToSaveWin">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
    <m-confirm-dialog v-model="confirmdelete"
      text="Souhaitez-vous effacer ce wine ?"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteWin()"></m-confirm-dialog>
    <candidat-jure-win-edit v-if="row_pa2"
      v-model="candidatJureWinEdit"
      :pa_id="row_pa2.pa_id"
      @WinEditAction="WinEditCandidatJureActions"></candidat-jure-win-edit>
  </div>
</template>

<script>
export default {
  name: "WineWinEdit",
  components: {},
  props: {
    wi_id: Number,
    row_pa: {
      default: function () {
        return {};
      },
      type: Object
    },
    value: { default: false, type: Boolean }
  },
  data() {
    return {
      confirmdelete: false,
      row_wi: { wi_rights: {}, wi_type: "" },
      row_pa2: {},
      candidatJureWinEdit: false
    };
  },
  watch: {
    value(v) {
      if (v) {
        if (this.row_pa) this.row_pa2 = this.row_pa;
        //this.contenants = [];
        //  this.loadDenominations();
        this.loadWine();
      }
    }
  },
  computed: {
    disabledMedaille() {
      if (this.$store.state.user.us_type == "superadmin") return false;
      return true;
    }
  },
  created() {},
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    async loadWine() {
      let params = {};
      if (this.wi_id == -1) params = {};
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/wines/" + this.wi_id,
        { params }
      );
      let row_wi = response.data.data;
      // console.log("🚀 ~ file: WineWinEdit.vue:219 ~ loadWine ~ row_wi:", row_wi)

      if (row_wi.participation && row_wi.participation.pa_id)
        this.row_pa2 = row_wi.participation;

      this.row_wi = row_wi;
    },
    async inputValidWine() {
      this.$refs.formWine.wineValidChange(this.row_wi.wi_valid);
    },
    async inputValidWine2() {
      this.$refs.formWine.wineValidChange2(this.row_wi.wi_valid2);
    },
    async inputMadailleWine() {
      this.$refs.formWine.wineMedailleChange(this.row_wi);
    },

    emitChangeCandidat(row_pa) {
      this.row_pa2 = row_pa;
      this.row_wi.participation = row_pa;
    },
    emitEditCandidatJure() {
      this.candidatJureWinEdit = true;
    },

    tryToSaveWin() {
      this.$refs.formWine.tryToSaveWin(this.row_pa2);
    },
    formWineAction(data) {
      this.$emit("input", false);
      this.$emit("WinEditActions", { action: data.action });
    },

    cancelWin() {
      this.$emit("input", false);
      this.$emit("WinEditActions", { action: "canceled" });
    },
    deleteConfirmWin() {
      this.$refs.formWine.deleteConfirmWin();
    },

    WinEditCandidatJureActions(data) {
      if (data.action === "saved") {
        this.row_pa2 = data.row_pa;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
