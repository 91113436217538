<template>
  <div v-if="row_in.wines">
    <div class="d-flex justify-content-between">
      <div>
        <h4 v-if="readOnly">
          {{ row_in.in_type === "FACTURE" ? "Facture " : "Avoir " }} N°
          {{ row_in.in_num }} | {{ row_in.wines.length }} vins |
          <span v-if="row_in.in_type == 'AVOIR'">-</span>{{ row_in.in_price_ht | formatPriceDivisePar100 }}
          <span v-if="row_in.in_typepaiement">
            | {{ row_in.in_typepaiement }}
          </span>
          <span v-if="row_in.in_payed"> | Payé</span>
          <span v-if="row_in.in_auto"> | Banque : {{ row_in.in_auto }}</span>
          <span v-if="row_in.in_type === 'AVOIR'">
            | {{ row_in.user | formatContactNameSimple }}</span>
          <span v-if="row_in.in_type === 'AVOIR'">
            | {{ row_in.createdAt | formatDate }}</span>
          <button @click="emitEditInvoice"
            class="btn btn-primary btn-sm ms-2 ">
            Modifier
          </button>
        </h4>
      </div>
      <div class="d-flex"
        v-if="!createAvoir">
        <!--<div
          class="d-flex justify-content-end"
          v-if="
            row_in.in_type === 'AVOIR' &&
              $store.state.user.us_type === 'superadmin'
          "
        >
          <div @click.stop="editAvoir()" class="pointer">
            <icon name="edit"></icon>
          </div>
          <div @click.stop="deleteConfirmAvoir()" class="ms-3 pointer">
            <icon color="#950605" name="trash-alt"></icon>
          </div>
        </div> -->
        <!--  <div @click="printEtiquette" class="pointer">
          <icon name="box-open"></icon>
        </div> -->
        <div @click="print"
          class=" pointer ms-2"
          v-tooltip
          data-bs-placement="left"
          :title="`Imprimer l${row_in.in_type === 'AVOIR' ? '\'avoir' : 'a facture'}`
            ">
          <icon name="print"></icon>
        </div>
        <div @click="sendFacture"
          class=" pointer ms-2"
          v-tooltip
          data-bs-placement="left"
          :title="`Envoyer l${row_in.in_type === 'AVOIR' ? '\'avoir' : 'a facture'
            } par email`
            ">
          <icon color="#3fb1dc"
            name="envelope"></icon>
        </div>
      </div>
    </div>
    <div>
      <m-list-simple tableClass="table-hover table-striped"
        ref="winelist"
        :dynamic="true"
        :items="row_in.wines"
        item-value="wi_id"
        :item-height="30"
        :skip="0"
        :total="row_in.wines.length"
        :cursorOnRows="true"
        @itemclick="canEditWine">
        <template v-slot:ths="{}">
          <th v-if="createAvoir"></th>
          <th>
            <span>Ajouté le</span>
          </th>
          <th>
            <span>Nom</span>
          </th>
          <th><span>Dénomination</span></th>
          <th>
            <span>Couleur</span>
          </th>

          <th><span>Millésime</span></th>
          <th><span>Valide</span></th>
          <th><span>Effacé</span></th>
          <th v-if="!createAvoir && row_in.in_type === 'FACTURE'">
            <span>Avoir</span>
          </th>

          <th></th>
        </template>
        <template v-slot:tds="{ item }">
          <td v-if="createAvoir">
            <input type="checkbox"
              id="selectWine"
              :name="$Utils.randomstring('selectWine')"
              @input="selectWine(item)"
              :checked="getValue(item)"
              :disabled="item.in_id_avoir !== 0" />
          </td>
          <td>
            {{ item.createdAt | formatDate }}
          </td>
          <td>
            {{ item | formatWineName }}
          </td>
          <td>
            {{
              item.denomination
                ? item.denomination.de_name
                : item.wi_denomination
            }}
          </td>
          <td>
            {{ item.wi_couleur | formatWineColor }}
          </td>

          <td>
            {{ item.wi_millesime | formatWineMillesime }}
          </td>
          <td v-html="$options.filters.formatYesNoColored(item.wi_valid)"></td>
          <td v-html="$options.filters.formatYesNoColored(item.wi_valid2)"></td>

          <td v-if="!createAvoir && row_in.in_type === 'FACTURE'">
            {{ item.in_id_avoir ? "Oui" : "" }}
          </td>

          <td>
            <div class="d-flex justify-content-end">
              <div @click.stop="editWine(item)"
                class="me-3"
                v-tooltip
                data-bs-placement="left"
                :title="`Modifier le vin`">
                <icon name="edit"></icon>
              </div>
            </div>
          </td>
        </template>
      </m-list-simple>
    </div>
    <div v-if="!createAvoir && readOnly && row_in.in_type !== 'AVOIR'"
      class="d-flex justify-content-end mt-3">
      <button @click="emitCreateAvoir"
        class="btn btn-primary">
        Créer un avoir >
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrameInvoice",
  components: {},
  props: {
    readOnly: Boolean,
    createAvoir: Boolean,
    avoirId: Number,
    row_in: {
      type: Object,
      default: function () {
        return { wines: [] };
      }
    },
    row_pa: {
      type: Object
    },
    keyload: Number
  },
  data() {
    return {
      price: 0,
      winesSelectedToAvoir: [],
      avoirWinEdit: false
    };
  },
  watch: {
    keyload: function (v) {},
    row_in: function (v) {
      // parfois in_id_avoir est à 0 parfois il est à null , on le met tt le temps à 0
      if (this.row_in.wines) {
        this.row_in.wines.map(wine => {
          if (!wine.in_id_avoir) wine.in_id_avoir = 0;
        });
      }
    }
  },
  computed: {},
  async mounted() {
    // parfois in_id_avoir est à 0 parfois il est à null , on le met tt le temps à 0
    if (this.row_in.wines) {
      this.row_in.wines.map(wine => {
        if (!wine.in_id_avoir) wine.in_id_avoir = 0;
      });
    }
  },
  methods: {
    getValue(item) {
      let val = false;
      if (item.in_id_avoir) val = true;
      return val;
    },
    emitCreateAvoir() {
      this.$emit("emitCreateAvoir", this.row_in.in_id);
    },
    canEditWine(item) {
      if (!this.createAvoir) {
        this.editWine(item);
      }
    },
    editWine(item) {
      this.$emit("emitEditWine", item);
    },
    emitEditInvoice() {
      this.$emit("emitEditInvoice", this.row_in.in_id);
    },
    async saveFacture() {
      let data = {
        in_typepaiement: this.row_in.in_typepaiement,
        in_price_ht: this.row_in.in_price_ht,
        in_payed: this.row_in.in_payed,
        in_auto: this.row_in.in_auto
      };
      let response = await this.$axios.put(
        this.$config.server_url +
        "/backoffice/1.0/invoices/" +
        this.row_in.in_id,
        data
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Edition d'un facture",
          text: "La facture a bien été modifiée",
          color: "green"
        });
        this.$emit("emitInvoicesAction");
      }
    },

    selectWine(item) {
      let index = this.winesSelectedToAvoir.findIndex(wine => {
        return wine.wi_id === item.wi_id;
      });
      if (index >= 0) {
        this.winesSelectedToAvoir.splice(index, 1);
      } else {
        this.winesSelectedToAvoir.push(item);
      }
      this.$emit("emitwinesAvoir", this.winesSelectedToAvoir);
    },
    print() {
      window.open(
        `${this.$config.server_url}/backoffice/1.0/invoices/${this.row_in.in_id}/print?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    printEtiquette() {
      window.open(
        `${this.$config.server_url}/backoffice/1.0/invoices/${this.row_in.in_id}/etiquette?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    async sendFacture() {
      let params = {};
      let response = await this.$axios.get(
        this.$config.server_url +
        "/backoffice/1.0/invoices/" +
        this.row_in.in_id +
        "/sendemail",
        { params }
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Candidat " + response.data.data.pa_society,
          text: "La facture a bien été envoyée",
          color: "green"
        });
      } else {
        this.$store.dispatch("showToast", {
          title: "Candidat " + response.data.data.pa_society,
          text: response.data.data.error,
          color: "red"
        });
      }
    }

    //// si invoices et un avoir
    /*    editAvoir() {
      this.$emit("emitAvoirEdit", this.row_in);
    },
    deleteConfirmAvoir() {
      this.confirmdelete = true;
    },
    async deleteA() {
      this.confirmdelete = false;
      let response = await this.$axios.delete(
        this.$config.server_url + "/backoffice/1.0/invoices/" + this.row_in.in_id
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Candidat",
          text: "L'avoir " + response.data.data.in_num + " a bien été supprimé",
          color: "green"
        });
      }
      this.$emit("emitAvoirAction");
    } */
  }
};
</script>

<style lang="scss" scoped></style>
